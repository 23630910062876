<template>
  <div :class="isMobile === null ? 'titleText' : (isMobile && isMobile.includes('iPad')) ? 'titleTextMobileIpad titleTextMobile' :'titleTextMobile'" >
    <div class="text">{{ cn }}</div>
    <div class="en">{{ en }}</div>
  </div>
</template>

<script>
export default {
  name: 'TitleText',
  inject: {
    isMobile: {
      default: null
    }
  },
  props: {
    cn: {
      type: String,
      require: true
    },
    en: {
      type: String,
      require: true
    }
  },
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
  .titleText{
    font-size: .208333rem;
    font-weight: 700;
    .en{
      // margin-top: .0069rem;
      color: #C4C4C4;
      font-size: .166667rem;
      font-family: '微软雅黑';
      font-weight: 500;
    }
  }
  .titleTextMobile{
    font-size: .4267rem;
    font-weight: 700;
    .en{
      color: #C4C4C4;
      font-family: '微软雅黑';
      font-weight: 500;
    }
  }
  .titleTextMobileIpad{
    font-size: .3767rem;
  }
</style>