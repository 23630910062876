<template>
  <div :class="isMobile == null ? 'banner3' : (isMobile && isMobile.includes('iPad'))? 'banner3iPad': 'banner3Mobile'">
    <div :class="isMobile === null ? 'title' : 'titleMobile'">
      <Ce />
      <TitleText :cn="'泽悦投资策略'" :en="'Our Investment Strategy'"/>
    </div>
    <div :class="isMobile === null ? 'main' : (isMobile && isMobile.includes('iPad'))? 'mainiPad':'mainMoblie'">
      <div class="bannerThree" />
      <div
        v-if="isMobile === null"
        class="text wow fadeIn"
        :data-wow-duration="animateConfig.duration"
        v-scroll-reveal.reset="scrollSet"
        :data-wow-delay="animateConfig.delay" >
        <p>后新冠疫情，中美关系，流动性泛滥带来的通胀/滞胀压力等因素使未来的投资
          环境日趋复杂，极富挑战。同时，过去三十年的新药创新机制也面临医保集采、
          同质化竞争、估值泡沫化等问题而变得不可持续。因此，对未来医药创新模式的
          思考与探索成为投资的重要成功要素。</p>
        <br />
        <p class=""><span> • </span> 非共识投资：回避市场炒作，短期热点带来的估值泡沫。对各子行业的产业链，创新到商业化应用的临界点进行深入的分析、研究，寻求“底层创新”。</p>
        <p></p>
        <p class=""><span> • </span> 惟精惟一：深耕长三角优质医疗大健康创新土壤；以投后服务助力企业成长；控制融投资规模，拒绝“流水线”式投资。</p>
        <p></p>
        <p class=""><span> • </span> 注重投资组合：多维度权衡投资组合，包括企业发展的生命周期，各子行业的增长和风险，政策和市场环境的变化。投资组合涵盖创新药、医疗器械/IVD、新医改模式下的医疗服务，强调“全球化商业机会”。</p>
        <p></p>
        <p></p>
      </div>
      <div v-else class="text">
        <p>后新冠疫情，中美关系，流动性泛滥带来的通胀/滞胀压力等因素使未来的投资
          环境日趋复杂，极富挑战。同时，过去三十年的新药创新机制也面临医保集采、
          同质化竞争、估值泡沫化等问题而变得不可持续。因此，对未来医药创新模式的
          思考与探索成为投资的重要成功要素。</p>
        <br />
        <p><span> • </span> 非共识投资：回避市场炒作，短期热点带来的估值泡沫。对各子行业的产业链，创新到商业化应用的临界点进行深入的分析、研究，寻求“底层创新”。</p>
        <p><span> • </span> 惟精惟一：深耕长三角优质医疗大健康创新土壤；以投后服务助力企业成长；控制融投资规模，拒绝“流水线”式投资。</p>
        <p><span> • </span> 注重投资组合：多维度权衡投资组合，包括企业发展的生命周期，各子行业的增长和风险，政策和市场环境的变化。投资组合涵盖创新药、医疗器械/IVD、新医改模式下的医疗服务，强调“全球化商业机会”。</p>
      </div>
    </div>
  </div>

</template>

<script>
import Ce from '@/views/home/component/bigText'
import { WOW, wowInit, animateConfig, scrollSet } from '@/util/scrollReveal.js'
import TitleText from '@/views/home/component/titleText'
export default {
  name: 'Banner3',
  props: {},
  inject: {
    isMobile: {
      default: null
    }
  },
  data () {
    return {
      animateConfig,
      scrollSet
    }
  },
  components: {
    Ce,
    TitleText
  },
  created () {},
  mounted () {
    const wowi = new WOW(wowInit)
    wowi.init()
  },
  computed: {},
  watch: {},
  methods: {},
}
</script>
<style scoped lang='scss'>
  .banner3{
    width: 10rem;
    margin-top: 1.3rem;
    position: relative;
    .title{
      position: relative;
      margin-bottom: .0486rem;
      overflow: hidden;
      .bigText{
      margin-left: 2.77rem;
      margin-bottom: 0.027053rem;
      background: url('/img/ce.png') no-repeat bottom/95% 100%;
    }
    .titleText{
      position: absolute;
      left: 3.527778rem;
      bottom: 0;
    }
    }
    .main{
      .text{
      width: 4.895833rem;
      padding-top: .3125rem;
      padding-right: .1736rem;
      padding-left: .0486rem;
      margin-left: .861111rem;
      margin-top: 1.2rem;
      color: #000000;
      background: rgba(255, 255, 255, 0.6);
      font-size: .125rem;
      font-weight: lighter;
      line-height: .277778rem;
      letter-spacing: .006944rem;
      .nowrap{
        white-space: nowrap;
      }
    }
    .bannerThree{
      width: 5.609583rem;
      height: 3.819444rem;
      z-index: -1;
      position: absolute;
      top: 1.0417rem;
      left: 3.527778rem;
      background: url('/img/bannar3.png') no-repeat center;
      background-size: 100%;
    }
    }
  }
  .banner3Mobile{
        width: 10rem;
    margin-top: .3rem;
        .titleMobile{
      margin-bottom: .1067rem;
      position: relative;
      height: 1.6rem;
      .bigText{
        z-index: -1;
        position: absolute;
        left: 1.3067rem;
        bottom: 0.16rem;
        width: 1.4933rem;
        height: 1.6rem;
        background: url('/img/ce.png') no-repeat bottom/95% 100%;
      }
      .titleTextMobile{
        position: absolute;
        bottom: 0;
        margin-left: 2.5333rem;
      }
    }
    .mainMoblie{
      line-height: .6667rem;
      .bannerThree{
        width: 6.9067rem;
        height: 4.72rem;
        background: url('/img/bannar3.png') no-repeat center/100% 100%;
        margin-left: 2.6667rem;
        // margin-top: .1333rem;
      }
      .text{
        margin-right: 2rem;
        padding: .25rem .3467rem 0 .4267rem;
        font-size: .3733rem;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: 1);
        position: relative;
        bottom: 1.5467rem;
        text-align: justify;
        background: rgba($color: #fff, $alpha: 0.75);
      }
    }
  }
  .banner3iPad{
      width: 10rem;
      margin-top: .3rem;
      .titleMobile{
        margin-bottom: .1067rem;
        position: relative;
        height: 1.6rem;
      .bigText{
        z-index: -1;
        position: absolute;
        left: 1.3067rem;
        bottom: 0.16rem;
        width: 1.4933rem;
        height: 1.6rem;
        background: url('/img/ce.png') no-repeat bottom/95% 100%;
      }
      .titleTextMobile{
        position: absolute;
        bottom: 0;
        margin-left: 2.5333rem;
      }
    }
    .mainiPad{
      line-height: .6667rem;
      .bannerThree{
        width: 6.9067rem;
        height: 4.72rem;
        background: url('/img/bannar3.png') no-repeat center/100% 100%;
        margin-left: 2.6667rem;
      }
      .text{
        margin-right: 2rem;
        padding: .25rem .3467rem 0 .4267rem;
        font-size: .25rem;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: 1);
        position: relative;
        bottom: 1.5467rem;
        text-align: justify;
        background: rgba($color: #fff, $alpha: 0.75);
      }
    }
  }
</style>